import { DEFAULT_HEADER_OFFSET, NO_HEADER_OFFSET, STICKY_HEADER_OFFSET } from 'activity-feed-ui/constants/HeaderOffsets';
import { REPORTING_DASHBOARD } from 'activity-feed-ui/constants/StreamSources';
export function getHeaderOffset({
  useCondensedView,
  streamSource
}) {
  if (streamSource === REPORTING_DASHBOARD) {
    return NO_HEADER_OFFSET;
  }
  if (useCondensedView) {
    return STICKY_HEADER_OFFSET;
  }
  return DEFAULT_HEADER_OFFSET;
}
export function getScrollableContainerState({
  scrollableContainerClassName,
  headerOffset = 0
}) {
  if (scrollableContainerClassName) {
    const scrollableNode = document.getElementsByClassName(scrollableContainerClassName)[0];
    if (!scrollableNode) {
      return {};
    }
    const {
      scrollTop,
      scrollHeight,
      clientHeight,
      clientWidth,
      offsetTop
    } = scrollableNode;
    const isCloseToBottom = scrollTop + clientHeight + 150 >= scrollHeight;
    return {
      isCloseToBottom,
      scrollableNode,
      scrollTop,
      offsetTop,
      height: clientHeight,
      width: clientWidth,
      backgroundRefreshOffset: `${clientWidth / 2 - 100}px`,
      isBackgroundRefreshButtonFixed: scrollTop > headerOffset
    };
  }
  const {
    innerHeight,
    innerWidth,
    pageYOffset
  } = window;
  const {
    offsetHeight
  } = document.body;
  const isCloseToBottom = innerHeight + pageYOffset + 50 >= offsetHeight;
  return {
    isCloseToBottom,
    offsetTop: 0,
    scrollTop: pageYOffset,
    height: innerHeight,
    width: innerWidth,
    backgroundRefreshOffset: 'calc(50% - 100px)',
    isBackgroundRefreshButtonFixed: pageYOffset > headerOffset,
    scrollableNode: window
  };
}
export function shouldFetchMoreActivities({
  isOverLimit,
  searchQueryData,
  activityBundles,
  isCloseToBottom
}) {
  return !isOverLimit && searchQueryData.hasMore && !activityBundles.isEmpty() && !searchQueryData.isSearching && !searchQueryData.searchError && isCloseToBottom;
}
export function updateBackgroundRefreshButton({
  isBackgroundRefreshButtonFixed,
  backgroundRefreshOffset
}) {
  const activityFeedContainerNode = document.getElementById('activity-feed-container');
  const backgroundRefreshButton = document.getElementsByClassName('background-refresh-button')[0];
  if (!backgroundRefreshButton) {
    return;
  }
  if (isBackgroundRefreshButtonFixed) {
    activityFeedContainerNode.classList.add('isBackgroundRefreshButtonFixed');
    backgroundRefreshButton.style.left = backgroundRefreshOffset;
  } else {
    activityFeedContainerNode.classList.remove('isBackgroundRefreshButtonFixed');
    backgroundRefreshButton.style.left = 'inherit';
  }
}
export function fetchMoreActivitiesIfNeeded({
  isOverLimit,
  searchQueryData,
  activityBundles,
  isCloseToBottom,
  debounceFetchMoreRef
}) {
  if (shouldFetchMoreActivities({
    isOverLimit,
    searchQueryData,
    activityBundles,
    isCloseToBottom
  })) {
    debounceFetchMoreRef.current.cancel();
    debounceFetchMoreRef.current();
  }
}
export function updateBackgroundRefreshButtonIfChanged({
  isBackgroundRefreshButtonFixedRef,
  nextIsBackgroundRefreshButtonFixed,
  backgroundRefreshOffset
}) {
  if (isBackgroundRefreshButtonFixedRef.current !== nextIsBackgroundRefreshButtonFixed) {
    isBackgroundRefreshButtonFixedRef.current = nextIsBackgroundRefreshButtonFixed;
    updateBackgroundRefreshButton({
      isBackgroundRefreshButtonFixed: isBackgroundRefreshButtonFixedRef.current,
      backgroundRefreshOffset
    });
  }
}
export function handleScrollAndUpdateRefs({
  scrollableContainerClassName,
  showOnboarding,
  isOverLimit,
  searchQueryData,
  activityBundles,
  headerOffsetRef,
  debounceFetchMoreRef,
  isBackgroundRefreshButtonFixedRef
}) {
  if (showOnboarding) {
    return;
  }
  const {
    isCloseToBottom,
    backgroundRefreshOffset,
    isBackgroundRefreshButtonFixed: nextIsBackgroundRefreshButtonFixed
  } = getScrollableContainerState({
    scrollableContainerClassName,
    headerOffset: headerOffsetRef.current
  });
  fetchMoreActivitiesIfNeeded({
    isOverLimit,
    searchQueryData,
    activityBundles,
    isCloseToBottom,
    debounceFetchMoreRef
  });
  updateBackgroundRefreshButtonIfChanged({
    isBackgroundRefreshButtonFixedRef,
    nextIsBackgroundRefreshButtonFixed,
    backgroundRefreshOffset
  });
}