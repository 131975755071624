import UserContainer from 'activity-feed-ui/dataContainers/UserContainer';
import GatesContainer from 'activity-feed-ui/dataContainers/GatesContainer';
const hasScope = scope => {
  const user = UserContainer.get();
  return user && user.scopes && user.scopes.indexOf(scope) !== -1;
};
const __hasGate = gate => {
  const gates = GatesContainer.get();
  return gates && gates.hubspotGates && gates.hubspotGates.includes(gate);
};
export const hasUnlimitedActivities = () => hasScope('activity-feed-unlimited-activities');
export const hasActivityFeedAccess = () => hasScope('activity-feed-access');
export const hasContactPropertyFilterAccess = () => hasScope('sequences-read-access');
export const hasProspectingWorkspaceAccess = () => hasScope('prospecting-workspace-access');
export const hasActivityAddOnsScope = () => hasScope('abm-target-account-overview-access') && hasScope('leads-read');
export const isUngatedtoNewNavSpringSpotlight = () => __hasGate('Navigation:NewNavIAForSpringSpotlight');