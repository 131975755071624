export const CHROME_EXTENSION = 'extension';
// This source can be removed after confirming sufficient saturation
// of the Chrome extension using the `extension` source
export const CHROME_EXTENSION_CRX = 'crx';
export const OUTLOOK_365_ADDIN = 'outlook-365';
export const REPORTING_DASHBOARD = 'reporting';
export const PROSPECTING_WORKSPACE = 'prospecting-ui'; // This is a relocation of webapp version and will follow the same rules
export const CUSTOMER_SUCCESS_WORKSPACE = 'customer-success-workspace-ui'; // No banner for this source
export const SALES_ACTIVITIES = 'sales-activities'; // No banner for this source
export const TASKS_APP = 'tasks';
export const TRAY_APP = 'trayapp';
export const WEB_APP = 'webapp';